<template>
  <InstantSearch
    :enable-routing="true"
    :contexts="contexts.split(',')"
    :filters="getFilters"
    :optional-filters="optionalFilters"
    :analytics-tags="contexts.split(',')"
    :hits-per-page="200"
  >
    <div class="FiltersOptions" v-if="sortFilters">
      <SearchBar :sortByItems="sortByItems" />
    </div>

    <Hits v-slot="{ hits }">
      <div class="Grid">
        <ProductCardFireworks
          v-for="hit in hits"
          :key="hit.objectID"
          class="Grid__item"
          :product="hit"
        >
        </ProductCardFireworks>
      </div>
    </Hits>
  </InstantSearch>
</template>

<script>
import { gsap } from 'gsap/all';

import { AisStateResults, AisSortBy } from 'vue-instantsearch';
import { InstantSearch, Hits } from '@components/Search';
import ProductCardFireworks from '@scenes/FireworksScene/components/ProductCardFireworks';
import SearchBar from '@components/Search/SearchBar';
import { routing, searchClient } from '@algolia';

import tracking from '@tracking';
import settings from '@settings';

export default {
  name: 'FireworksProductsScene',

  components: {
    InstantSearch,
    Hits,
    ProductCardFireworks,
    AisSortBy,
    SearchBar,
  },

  props: {
    optionalFilters: {
      type: String,
      default: '',
    },
    filters: {
      type: String,
      default: '',
    },
    contexts: {
      type: String,
      default: '',
    },
    sortFilters: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ruleItems: [],
      sortByItems: [
        {
          value: settings.search.indices.products,
          label: this.$t('popularity'),
        },
        {
          value: settings.search.indices.products + '--priceAsc',
          label: this.$t('fireworks.sort-price-asc'),
        },
        {
          value: settings.search.indices.products + '--priceDesc',
          label: this.$t('fireworks.sort-price-desc'),
        },
      ],
    };
  },

  computed: {
    getFilters() {
      return this.filters;
    },
  },

  created() {
    // Listen for this component role event
    this.settings = settings;
  },

  methods: {
    scrollUp() {
      gsap.to(window, { duration: 0.8, delay: 0.2, scrollTo: { y: this.$el, offsetY: 80 } });
    },
  },
};
</script>

<style lang="scss" scoped>
.Grid {
  margin-bottom: 40px;
}

.FiltersOptions {
  display: flex;
  margin-bottom: 40px;

  @media only screen and (min-width: $tablet) {
    margin-bottom: 20px;
  }

  .ValuesAsText {
    span {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
</style>
<style lang="scss">
.FiltersOptions {
  .ValuesAsText {
    span {
      font-weight: bold;
      font-size: 18px;
      @include maxBreakpoint(600) {
        font-size: 14px;
      }
    }
  }
}
</style>
