<template>
  <div class="product-item item" :data-filter="returnProductFilters" :data-price="product.price">
    <div class="quickNo" v-if="product.quick_number && $isEnabled(Features.FIREWORKS_QUICK_NUMBER)">
      <span v-text="quickNumberLabel()"></span>
      {{ product.quick_number }}
    </div>
    <div class="product-thumb">
      <a :href="product.url">
        <div v-if="product.al_toggles.is_new" class="nyhed">{{ $t('label-new') }}</div>
        <i v-if="!product.image" class="fal fa-image"></i>
        <ImageHandler
          v-else
          :source="getImageUrl(product.item_number, 330, 232)"
          :title="product.title"
        />
      </a>
    </div>
    <div class="product-name">
      <a :href="product.url" class="item_name"
        ><span>{{ product.title }}</span></a
      >
      <div
        class="product-available-from"
        v-if="
          $config.DOMAIN_CODE !== 'TNO' && !hidePrice && !product.available && product.available_at
        "
      >
        Kan købes fra
        <Time :datetime="product.available_at" format="D. MMMM" />
      </div>
    </div>

    <div class="price-box" style="min-height: 45px;">
      <span class="product-price item_price"><Price :value="product.price"/></span>
      <span style="display:none">{{ product.item_number }}</span>
      <a
        v-if="($config.DOMAIN_CODE === 'TNO' || product.available) && !hidePrice"
        href="javascript:void(0);"
        class="add-cart-btn additem pull-right"
        data-hvor="search"
        title="tilføj til liste"
        ><span class="fa-stack"><i class="fal fa-shopping-cart fa-stack-1x fa-2x"></i></span
      ></a>
    </div>

    <div v-if="showStockStatus && StockStatus" class="StockStatus" v-html="StockStatus"></div>
  </div>
</template>

<script>
import settings from '@settings';
import Price from '@components/Price';
import ImageHandler from '@components/ImageHandler';
import Cookies from 'js-cookie';
import axios from 'axios';
import Time from '@components/Time';
import Features from '@types/Features';

export default {
  name: 'ProductCardFireworks',

  components: {
    Price,
    ImageHandler,
    Time,
  },
  enums: {
    Features,
  },
  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoaded: false,
      checkProductStatusTimeout: undefined,
      isVisible: false,
      settings,
      showStockStatus: false,
      StockStatus: '',
      hidePrice: true,
    };
  },

  methods: {
    getImageUrl(imageID, width, height) {
      let image_url = '';

      image_url =
        'https://dyncdn.thg.dk/img/showimgcolor/' +
        imageID +
        '_0_' +
        height +
        '_' +
        width +
        '_40_40_40.png';

      return image_url;
    },

    quickNumberLabel() {
      let label = 'Kvik nr.';
      if (gloLanguage === 'no') {
        label = 'Kvikk nr.';
      }

      return label;
    },

    getStockStatus() {
      //if (window.showItemStockStatus) {
      if (Cookies.get('localFyrShop') !== undefined) {
        //console.log(Cookies.get('localFyrShop'));

        axios
          .get(
            'https://xapi.thg.dk/pyro/Product/GetStockStatus/' +
              Cookies.get('localFyrShop') +
              '/' +
              this.product.node_id,
            {
              headers: {
                'Ocp-Apim-Subscription-Key': window.azureSubscription,
                c: Cookies.get('c'),
              },
            },
          )
          .then(response => {
            let itemsInStock = response.data.data;

            let shopName = itemsInStock[0].branch.name.replace('Fyrværkeri', '');
            let shopStock = itemsInStock[0].stock_level;
            let statusMessage =
              "<div class='ItemStockStatus'><span>På lager lige nu</span><span class='location'><span class='hidden-xs'>Udleveringssted: </span>" +
              shopName +
              '</span></div>';

            if (shopStock == 0) {
              statusMessage =
                "<div class='ItemStockStatus soldout'><span>Udsolgt lige nu</span><span class='location'><span class='hidden-xs'>Udleveringssted: </span>" +
                shopName +
                '</span></div>';
            } else if (shopStock < 6) {
              statusMessage =
                "<div  class='ItemStockStatus low'><span>Få tilbage</span><span class='location'><span class='hidden-xs'>Udleveringssted: </span>" +
                shopName +
                '</span></div>';
            } else if (shopStock > 6 && shopStock < 50) {
              statusMessage =
                "<div class='ItemStockStatus'><span>På lager lige nu</span><span class='location'><span class='hidden-xs'>Udleveringssted: </span>" +
                shopName +
                '</span></div>';
            } else {
              statusMessage =
                "<div class='ItemStockStatus'><span>På lager lige nu</span><span class='location'><span class='hidden-xs'>Udleveringssted: </span>" +
                shopName +
                '</span></div>';
            }
            //console.log(statusMessage);
            this.StockStatus = statusMessage;
            //self.StockStatus = 'test';
          })
          .catch(function(error) {
            console.log('Error: ', error);
          });
      }
      //}
    },
  },

  created() {
    this.hidePrice = !window.gloFyrSaleStarted;
    if (window.showItemStockStatus) {
      this.showStockStatus = true;

      if (simpleCart) {
        simpleCart.bind('update', () => {
          if (this.checkProductStatusTimeout) clearTimeout(this.checkProductStatusTimeout);
          this.checkProductStatusTimeout = setTimeout(this.getStockStatus, 600);
        });
      }
    }
  },
  destroyed() {
    if (this.checkProductStatusTimeout) clearTimeout(this.checkProductStatusTimeout);
  },
  mounted() {},

  computed: {
    returnProductFilters() {
      return JSON.stringify({
        vn: this.product.item_number,
        kvik: String(this.product.quick_number),
        pris: this.product.price,
        pn: this.product.node_id,
      });
    },

    encodeUrl() {
      let url =
        '/fyrvaerkeri/' +
        this.product.parent.name +
        '/' +
        this.product.title +
        '/' +
        this.product.node_id;
      let sname = url.toLowerCase();
      sname = sname.replace(/æ/gi, 'ae');
      sname = sname.replace(/ø/gi, 'o');
      sname = sname.replace(/å/gi, 'aa');
      sname = sname.replace(/,/gi, '');
      //sname = sname.replace(".", "")
      sname = sname.replace(/ /gi, '-');
      sname = sname.replace(/[&]/g, 'og');
      sname = sname.replace(/[!@#$%^*".']/g, '');

      return sname;
    },
  },
};
</script>

<style lang="scss">
.product-item {
  position: relative;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  background: #00519a;
  background: radial-gradient(circle, #00519a 0%, #00294e 100%);

  .quickNo {
    background-color: #ce1212;
    position: absolute;
    top: 8px;
    right: 4px;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    z-index: 6;
    width: 44px;
    height: 44px;
    padding-top: 10px;
    color: #fff;
    border-radius: 50%;

    span {
      position: absolute;
      text-transform: uppercase;
      font-size: 10px;
      background-color: #000000;
      width: 50px;
      left: -2px;
      top: -8px;
      line-height: 18px;
    }
  }
  .product-available-from {
    font-size: 12px;
    position: absolute;
    top: -6px;
    text-align: center;
    width: 100%;
    color: #feed01;
    left: 0;
  }
  .product-thumb {
    flex: 1;
    padding: 5px;
    text-align: center;
    position: relative;

    .nyhed {
      position: absolute;
      right: 5px;
      bottom: 11px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      background-color: #00539b;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 19px 6px 0px;
      color: #fff;
    }

    img {
      max-width: 100%;
      min-height: 134px;

      @include maxBreakpoint(600) {
        min-height: auto;
      }
    }

    i {
      font-size: 42px;
      padding-top: 60px;
      padding-bottom: 50px;
      opacity: 0.8;
    }
  }

  .product-name {
    flex: 1;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    position: relative;
    @include maxBreakpoint(600) {
      font-size: 13px;
    }
  }

  .price-box {
    flex: 1;
    margin-top: 0px;
    background-color: #021e39;

    .product-price {
      font-size: 18px;
      padding: 10px;
      font-weight: bold;
      position: absolute;

      @include maxBreakpoint(600) {
        font-size: 13px;
        padding: 15px 10px 12px;
      }
    }
    .add-cart-btn {
      background-color: #fff;

      i {
        color: #000;
      }
    }
  }

  .StockStatus .ItemStockStatus {
    border-top: 2px solid #000;
  }

  .product-item {
    .quickNo {
      background-color: #ce1212;
      position: absolute;
      top: 2px;
      right: 2px;
      text-align: center;
      font-size: 15px;
      line-height: 30px;
      z-index: 6;
      width: 30px;
      height: 30px;
      padding-top: 4px;
      color: #fff;
      border-radius: 50%;

      &:before {
        content: 'Kvik nr.';
        position: absolute;
        text-transform: uppercase;
        font-size: 7px;
        background-color: #000000;
        width: 32px;
        left: -2px;
        top: -2px;
        line-height: 13px;
      }
    }

    .product-thumb {
      min-height: 110px;

      a {
        img {
          width: 100%;
        }

        .nyhed {
          position: absolute;
          bottom: 5px;
          right: 5px;
          top: auto;
          left: auto;
          font-size: 12px;
          text-transform: uppercase;
          background-color: rgba(0, 0, 0, 0.7);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          padding: 12px 4px 0px;
          color: #fff;
        }
      }
    }

    .product-name {
      height: 40px;

      a {
        font-size: 14px;
        line-height: 14px;
      }
    }

    .price-box {
      height: 45px;

      .item_price {
        font-size: 20px !important;
        margin-top: 10px;
      }

      .add-cart-btn {
        margin-right: 0px;
      }
    }
  }
}
</style>
